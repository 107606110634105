import { Component, Input } from '@angular/core';
import { IUser } from "../../../core/models/user/user.model";

@Component({
  selector: 'app-user-profile-box',
  templateUrl: './user-profile-box.component.html',
  styleUrls: ['./user-profile-box.component.scss']
})
export class UserProfileBoxComponent {
  @Input() user?: IUser;
  @Input() streamMode: boolean = false;
}
