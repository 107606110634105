import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { LoaderComponent } from './loader/loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PaginationComponent } from './pagination/pagination.component';
import { TranslateModule } from "@ngx-translate/core";
import { SearchInputComponent } from './search-input/search-input.component';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { CopiableTextComponent } from './copiable-text/copiable-text.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { CkeditorComponent } from './ckeditor/ckeditor.component';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UserProfileBoxComponent } from './user-profile-box/user-profile-box.component';
import { GrayIconComponent } from "./gray-icon/gray-icon.component";
import { GrayButtonComponent } from "./gray-button/gray-button.component";
import { GrayCheckboxComponent } from "./gray-checkbox/gray-checkbox.component";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { GrayTextInputComponent } from "./gray-text-input/gray-text-input.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { GraySelectInputComponent } from "./gray-select-input/gray-select-input.component";
import { GrayRadioComponent } from "./gray-radio/gray-radio.component";
import { ContainerSpinnerComponent } from "./container-spinner/container-spinner.component";
import { MaskSensitivePipe } from "../pipes/mask-sensitive.pipe";

@NgModule({
  declarations: [
    PagetitleComponent,
    LoaderComponent,
    SpinnerComponent,
    PaginationComponent,
    SearchInputComponent,
    DatetimePickerComponent,
    CopiableTextComponent,
    DatePickerComponent,
    CkeditorComponent,
    UserProfileBoxComponent,
    GrayIconComponent,
    GrayButtonComponent,
    GrayCheckboxComponent,
    ContainerSpinnerComponent,
    GrayCheckboxComponent,
    GrayTextInputComponent,
    GraySelectInputComponent,
    GrayRadioComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule,
    TranslateModule,
    BsDatepickerModule,
    CKEditorModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgSelectModule,
    MaskSensitivePipe,
  ],
  exports: [
    PagetitleComponent,
    LoaderComponent,
    SpinnerComponent,
    PaginationComponent,
    SearchInputComponent,
    DatetimePickerComponent,
    CopiableTextComponent,
    DatePickerComponent,
    CkeditorComponent,
    UserProfileBoxComponent,
    GrayIconComponent,
    GrayButtonComponent,
    GrayCheckboxComponent,
    GrayTextInputComponent,
    GraySelectInputComponent,
    GrayRadioComponent,
    GrayCheckboxComponent,
    ContainerSpinnerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UIModule {
}
