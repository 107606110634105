import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class LessonService {
  private readonly apiPrefix = '/admin/lessons'

  constructor(
    private api: ApiService
  ) {
  }

  createLesson(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  getLessons(filter = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}${filter}`);
  }

  updateLesson(id, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deleteLesson(id): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getLessonTopics(lessonId: number, queryString = '') {
    return this.api.get(`${this.apiPrefix}/${lessonId}/topics${queryString}`);
  }
}
