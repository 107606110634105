import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiveStreamModeService {
  private liveStreamModeSubject = new BehaviorSubject<boolean>(this.getCurrentMode());
  liveStreamMode$ = this.liveStreamModeSubject.asObservable();

  constructor() {
  }

  getCurrentMode(): boolean {
    return localStorage.getItem('liveStreamMode') === 'true';
  }

  toggleMode(): void {
    const currentMode = !this.liveStreamModeSubject.value;
    this.liveStreamModeSubject.next(currentMode);
    localStorage.setItem('liveStreamMode', String(currentMode));
    // window.location.reload();
  }
}
