import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { IContentPublishOptions } from "../../interfaces/content-publish-options.interface";

@Injectable()
export class CommunityPostService {
  private apiPrefix = '/admin/community-post';

  constructor(
    private api: ApiService
  ) {
  }

  createPost(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updatePost(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deletePost(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  getPostWithId(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`,);
  }

  getPosts(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`,);
  }

  publish(id: number, opts: IContentPublishOptions) {
    const body = {
      notify: opts.notify
    };
    if (opts?.publishDate)
      body['publishDate'] = opts.publishDate;

    return this.api.patch(`${this.apiPrefix}/${id}/publish`, body);
  }

  unpublish(id: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/unpublish`, {});
  }

  createImage(id: number, data: object) {
    return this.api.post(`${this.apiPrefix}/${id}/image`, data);
  }

  saveImage(id: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${id}/image/${requestId}`, {});
  }

  deleteImage(postId: number, imageId: number) {
    return this.api.delete(`${this.apiPrefix}/${postId}/image/${imageId}`);
  }

  getRelatedVideo(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/related-video`);
  }

  attachRelatedVideo(id: number, videoId: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/related-video`, {videoId});
  }

  unAttachRelatedVideo(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}/related-video`);
  }

  getRelatedSurvey(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/related-survey`);
  }

  attachRelatedSurvey(id: number, surveyId: number) {
    return this.api.patch(`${this.apiPrefix}/${id}/related-survey`, {surveyId});
  }

  unAttachRelatedSurvey(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}/related-survey`);
  }
}
