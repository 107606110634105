import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from "./services/auth/auth.service";
import { ApiService } from "./services/api.service";
import { MetaTagService } from "./services/meta-tag.service";
import { JwtService } from "./services/jwt.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { HttpErrorInterceptor } from "./interceptors/http-error.interceptor";
import { AppAbilityService } from "./services/auth/app-ability.service";
import { ProfileService } from "./services/admin-user/profile.service";
import { AdminService } from "./services/admin-user/admin.service";
import { ExamPeriodService } from "./services/exam/exam-period.service";
import { ExamService } from "./services/exam/exam.service";
import { VideoSourceService } from "./services/video/video-source.service";
import { LevelService } from "./services/level/level.service";
import { VideoService } from "./services/video/video.service";
import { DepartmentService } from "./services/lesson/department.service";
import { LessonService } from "./services/lesson/lesson.service";
import { TopicService } from "./services/lesson/topic.service";
import { LiveStreamWsService } from "./services/live-stream/live-stream-ws.service";
import { LiveStreamService } from "./services/live-stream/live-stream.service";
import { PlanService } from "./services/plan/plan.service";
import { LessonGroupService } from "./services/lesson/lesson-group.service";
import { PlaylistService } from "./services/video/playlist.service";
import { PublisherService } from "./services/exam/publisher.service";
import { DocumentService } from "./services/document/document.service";
import { ApplicationSettingService } from "./services/application-setting/application-setting.service";
import { LiveStreamPollService } from "./services/live-stream/live-stream-poll.service";
import { UserService } from "./services/user/user.service";
import { GradeService } from "./services/lesson/grade.service";
import { LocationService } from "./services/location/location.service";
import { ExamScoreService } from "./services/exam/exam-score.service";
import { UserPlanService } from "./services/plan/user-plan.service";
import { ExamMentorService } from "./services/exam/exam-mentor.service";
import { BlogCategoryService } from "./services/blog/blog-category.service";
import { BlogArticleService } from "./services/blog/blog-article.service";
import { NotificationService } from "./services/notification/notification.service";
import { PlanPriceService } from "./services/payment/plan-price.service";
import { UserPaymentService } from "./services/payment/user-payment.service";
import { VideoCommentService } from "./services/video/video-comment.service";
import { ProfanityFilterService } from "./services/profanity-filter/profanity-filter.service";
import { SchoolService } from "./services/school/school.service";
import { CampaignService } from "./services/campaign/campaign.service";
import { UserDailyWorkReportService } from "./services/plan/user-daily-work-report.service";
import { RoleService } from "./services/admin-user/role.service";
import { PermissionService } from "./services/admin-user/permission.service";
import { WorkbookService } from "./services/exam/workbook.service";
import { SurveyService } from "./services/video/survey.service";
import { LiveStreamModeService } from "./services/live-stream-mode/live-stream-mode.service";
import { CommunityPostService } from "./services/community/community-post.service";
import { CommunityCommentService } from "./services/community/community-comment.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    ApiService,
    AppAbilityService,
    AuthService,
    ProfileService,
    AdminService,
    MetaTagService,
    JwtService,
    ExamPeriodService,
    ExamService,
    VideoSourceService,
    LevelService,
    VideoService,
    SurveyService,
    CommunityPostService,
    CommunityCommentService,
    DepartmentService,
    LessonService,
    TopicService,
    LiveStreamWsService,
    LiveStreamService,
    PlanService,
    LessonGroupService,
    PlaylistService,
    PublisherService,
    DocumentService,
    ApplicationSettingService,
    LiveStreamPollService,
    UserService,
    GradeService,
    LocationService,
    ExamScoreService,
    UserPlanService,
    ExamMentorService,
    BlogCategoryService,
    BlogArticleService,
    NotificationService,
    PlanPriceService,
    UserPaymentService,
    VideoCommentService,
    ProfanityFilterService,
    SchoolService,
    CampaignService,
    UserDailyWorkReportService,
    RoleService,
    PermissionService,
    WorkbookService,
    LiveStreamModeService,
  ],
})
export class CoreModule {
}
