import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';

import { StatComponent } from './stat/stat.component';
import { TransactionComponent } from './transaction/transaction.component';
import { LiveStreamModeComponent } from "./live-stream-mode/live-stream-mode.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [StatComponent, TransactionComponent, LiveStreamModeComponent],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    UiSwitchModule,
    TranslateModule
  ],
  exports: [StatComponent, TransactionComponent, LiveStreamModeComponent]
})
export class WidgetModule {
}
