import { Component, OnInit } from '@angular/core';
import { LanguageService } from "./core/services/language.service";
import 'moment/locale/tr';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private lang: LanguageService,
  ) {
  }

  ngOnInit() {
    moment.locale('tr');
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
}
