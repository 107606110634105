import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class SurveyService {
  private readonly apiPrefix = '/admin/survey';

  constructor(
    private api: ApiService
  ) {
  }

  getSurveys(qs: string = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }

  getSurvey(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}`);
  }

  getSurveyCustomAnswers(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/custom-answers`);
  }

  getSurveyReport(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/report`);
  }

  exportSurveyReport(id: number) {
    return this.api.get(`${this.apiPrefix}/${id}/report/export`, new HttpParams(), 'blob');
  }

  createSurvey(data): Observable<any> {
    return this.api.post(this.apiPrefix, data);
  }

  updateSurvey(id: number, data): Observable<any> {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  deleteSurvey(id: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }

  setSurveyActiveState(id: number, isActive: boolean) {
    return this.api.patch(`${this.apiPrefix}/${id}/publish`, {isActive: isActive});
  }
}
