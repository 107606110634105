import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class LiveStreamService {
  private readonly apiPrefix = '/admin/live-streams';

  constructor(
    private api: ApiService,
  ) {
  }

  createLiveStream(data): Observable<any> {
    return this.api.post(`${this.apiPrefix}`, data);
  }

  updateLiveStream(id: number, data) {
    return this.api.patch(`${this.apiPrefix}/${id}`, data);
  }

  getLiveStreams(queryString = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}${queryString}`);
  }

  uploadThumbnailRequest(id: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${id}/thumbnail`, data);
  }

  saveThumbnail(id: number, requestId: string) {
    return this.api.patch(`${this.apiPrefix}/${id}/thumbnail/${requestId}`, {});
  }

  publishLiveStream(id: number, data): Observable<any> {
    return this.api.post(`${this.apiPrefix}/${id}/publish`, data);
  }

  getLiveStreamSource(id: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}/source`);
  }

  getLiveStreamStats(id: number): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}/view-stats`);
  }

  startLiveStream(id: number): Observable<any> {
    return this.api.put(`${this.apiPrefix}/${id}/starting`, {});
  }

  startedLiveStream(id: number): Observable<any> {
    return this.api.put(`${this.apiPrefix}/${id}/started`, {});
  }

  stopLiveStream(id: number): Observable<any> {
    return this.api.put(`${this.apiPrefix}/${id}/stop`, {});
  }

  getChatMessages(id: number, qs = ''): Observable<any> {
    return this.api.get(`${this.apiPrefix}/${id}/chat-messages${qs}`);
  }

  attachLiveStreamToVideo(id: number, data: object) {
    return this.api.patch(`${this.apiPrefix}/${id}/recording-video`, data);
  }

  deleteVideoFromLiveStream(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}/recording-video`);
  }

  deleteLiveStream(id: number) {
    return this.api.delete(`${this.apiPrefix}/${id}`);
  }
}
