import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class UserPlanService {
  private apiPrefix = '/admin/user-plans/users/';

  constructor(
    private api: ApiService
  ) {
  }

  getUserWeeks(userId: number) {
    return this.api.get(`${this.apiPrefix}${userId}/weeks`);
  }

  getUserWeekItems(userId: number, weekId: number) {
    return this.api.get(`${this.apiPrefix}${userId}/weeks/${weekId}/items`);
  }

  createWeekItem(userId: number, weekId: number, data: object) {
    return this.api.post(`${this.apiPrefix}${userId}/weeks/${weekId}/items`, data);
  }

  copyWeekItem(userId: number, itemId: number, data: object) {
    return this.api.post(`${this.apiPrefix}${userId}/week-items/${itemId}/copy`, data);
  }

  deleteWeekItem(userId: number, itemId: number) {
    return this.api.delete(`${this.apiPrefix}${userId}/week-items/${itemId}`);
  }

  transferWeekItem(userId: number, weekId: number, itemId: number, data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}${userId}/weeks/${weekId}/items/${itemId}/transfer`, data);
  }

  completeWeekItem(userId: number, weekId: number, itemId: number): Observable<any> {
    return this.api.post(`${this.apiPrefix}${userId}/weeks/${weekId}/items/${itemId}/complete`, {});
  }

  uncompleteWeekItem(userId: number, weekId: number, itemId: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}${userId}/weeks/${weekId}/items/${itemId}/complete`);
  }

  assignWeekItem(userId: number, weekId: number, itemId: number, data: object): Observable<any> {
    return this.api.post(`${this.apiPrefix}${userId}/weeks/${weekId}/items/${itemId}/assign`, data);
  }


  unassignWeekItem(userId: number, weekId: number, itemId: number): Observable<any> {
    return this.api.delete(`${this.apiPrefix}${userId}/weeks/${weekId}/items/${itemId}/assign`);
  }

  searchWeekItem(userId: number, filter: { lessonId?: number, lessonTopicId?: number }) {
    return this.api.get(`${this.apiPrefix}${userId}/week-items/search?` + Object.entries(filter).map(([k, value]) => `filter[${k}]=${value}`).join('&'));
  }
}
