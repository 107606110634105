import { Component } from '@angular/core';

@Component({
  selector: 'app-container-spinner',
  templateUrl: './container-spinner.component.html',
  styleUrls: ['./container-spinner.component.scss']
})
export class ContainerSpinnerComponent {

}
