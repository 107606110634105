export enum EPermissionKeys {
  Admins = 'admin',
  Users = 'users',
  ExamPeriods = 'exam-periods',
  ExamScores = 'exam-scores',
  Lessons = 'lessons',
  LessonTopics = 'lesson-topics',
  Video = 'videos',
  Playlist = 'playlists',
  VideoSources = 'video-sources',
  LiveStreams = 'live-streams',
  Plans = 'plans',
  UserPlans = 'user-plans',
  Documents = 'documents',
  ApplicationSettings = 'application-settings',
  ExamMentors = 'exam-mentors',
  Blog = 'blog',
  Notifications = 'notifications',
  PlanPrices = 'plan-prices',
  UserPayments = 'user-payments',
  VideoComments = 'video-comments',
  Campaigns = 'campaigns',
  Roles = 'roles',
  Publishers = 'publishers',
  Schools = 'schools',
  ProfanityFilter = 'profanity-filter',
  Workbooks = 'workbooks',
  Survey = 'survey',
  CommunityPost = 'community-post',
  CommunityComments = 'community-comments',
}
