// mask-sensitive.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskSensitive',
  standalone: true
})
export class MaskSensitivePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, visibleChars: number = 2, mask: boolean = true): string {
    if (!value) return value;

    return mask
      ? `${value.slice(0, visibleChars)}${'*'.repeat(5)}`
      : value;
  }
}
