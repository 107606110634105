import { Component, OnInit } from '@angular/core';
import { LiveStreamModeService } from "../../../core/services/live-stream-mode/live-stream-mode.service";

@Component({
  selector: 'app-live-stream-mode',
  templateUrl: './live-stream-mode.component.html',
  styleUrls: ['./live-stream-mode.component.scss']
})
export class LiveStreamModeComponent implements OnInit {
  isLiveStreamModeActive: boolean = false;

  constructor(private liveStreamModeService: LiveStreamModeService) {}

  ngOnInit(): void {
    this.liveStreamModeService.liveStreamMode$.subscribe(
      (mode) => (this.isLiveStreamModeActive = mode)
    );
  }

  toggleLiveStreamMode(): void {
    this.liveStreamModeService.toggleMode();
  }
}
