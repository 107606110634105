import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-gray-select-input',
  templateUrl: './gray-select-input.component.html',
  styleUrls: ['./gray-select-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GraySelectInputComponent
    },
  ]
})
export class GraySelectInputComponent implements ControlValueAccessor {

  @Input() bindLabel?: string;
  @Input() bindValue?: any;
  @Input() items: any[] = [];
  @Input() searchable = false;
  @Input() placeholder: string = '';
  @Input() hasError = false;
  @Input() customClass = '';
  @Input() clearable = true;
  @Input() searchFn?: (term: string, item: any) => boolean;
  @Output() changed = new EventEmitter();

  value: any = null;
  touched = false;
  @Input() disabled = false;
  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  onValueChange(val: any) {
    let value = (this.bindValue && val) ? val[this.bindValue] : val;
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.changed.emit(value);
      this.onChange(this.value);
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
